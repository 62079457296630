import React, { useState,Component } from 'react';
import DataTable from "react-data-table-component";
import { checkAuthed, handleDelete } from "../../../utils";
import Container from "../../common/Container";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { API_URL } from "../../../utils";
import _ from 'lodash';
import Switch from '@material-ui/core/Switch';
import { APP_URL, VENDOR_PANEL_URL } from '../../../utils';
import { Link } from 'react-router-dom';
import CryptoJS from "crypto-js";
import Toggle from '@material-ui/core/Switch';
import { saveAs } from "file-saver";

import XLSX from "xlsx";
import Tooltip from '@material-ui/core/Tooltip';
import { Box,Typography,Modal,Button } from '@material-ui/core';
// import Tooltip from 'react-bootstrap/Tooltip';
// import Papa from 'papaparse';
import AddButton from '../../common/AddButton';

// import { Modal, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
// import VendorList from './vendorbackup';

//Modal style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ViewProfileButton = ({
  viewUrl,
  isActive,
  handleEdit,
  handleDelete,
  vendorDetailsUrl,
  handleGuestLogin,
}) => (
  <div className="container">
    <div className="d-flex flex-row justify-center" style={{ gap: '7px' }}>
      <a
        className="btn btn-dark p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View Store"
      >
        <span className="material-symbols-outlined font-bold">
          open_in_new
        </span>
      </a>



      <Link
        className="btn btn-dark p-2"
        to={vendorDetailsUrl}
         target="_blank"
        rel="noreferrer"
        title="View Details"
      >
        <i className="fas fa-eye" />
      </Link>
      <button
        className="btn btn-dark p-2"
        title="Guest Login"
        onClick={handleGuestLogin}
      >
        <i className="fas fa-sign-in-alt" />
      </button>
      <button
        className="btn btn-dark p-2"
        onClick={handleEdit}
        title="Add Note"
      >
        <i className="fas fa-sticky-note" />
      </button>
      <button className="btn btn-dark p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
      <i className='fa fa-circle mt-2' style={{ color: isActive ? 'green' : 'red', fontSize: '15px' }}></i>
    </div>
  </div>
);


export const getProductTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}shop/admin/v2`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;
    // console.log("data length", data.length);
    console.log("v2 data",data);

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { data: [], total_shop: 0 };
  }
};



class VendorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
      featuredOnly: false,
      open: false, // State to control the Toggle (Switch)
      modalOpen: false // State to control the Modal visibility
      // searchBy: 'title', // Default searchBy field set to 'title' (VendorList Name)
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  handleToggleChange = (event) => {
    console.log(event.target.checked);
    this.setState({
      open: event.target.checked, // Update the Toggle state
      modalOpen: event.target.checked // Open the Modal when Toggle is checked
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false // Close the Modal
    });
  };


  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.getURLParams();
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getProductTableConfig(currentPage, limit, search, sortBy, sort);
      if (data) { 
        this.setState({ data: this.state.featuredOnly ? data.data.filter(item => item.activate === this.state.featuredOnly) : data.data, 
        totalRows: data.total_shop, fetched: true }); }
     
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort,
      sortBy
    }).toString();
    this.props.history.push(`?${queryParams}`);
  };

  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const limit = parseInt(searchParams.get('limit')) || 10;
    const search = searchParams.get('search') || '';
    const sort = parseInt(searchParams.get('sortBy')) || -1; 
    const sortBy = searchParams.get('sort') || 'createdAt';
    
    return { currentPage, limit, search, sort, sortBy };
  }

downloadAsXls = () => {
  const { data } = this.state;
  const columns = this.columns.map(col => ({ name: col.name, selector: col.selector }));
  const tableName = 'VendorData'; // Customize this as needed
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: tableName,
    Subject: "Exported Data",
    Author: "Your Company",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Sheet1");
  // Prepare header
  const header = columns.map(col => col.name);
  const ws_data = [header];
  // Prepare data rows
  data.forEach(row => {
    const rowData = columns.map(col => row[col.selector] || ''); // Adjust based on your data structure
    ws_data.push(rowData);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Sheet1"] = ws;
  // Convert to binary string and save
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${tableName}_${new Date().toISOString().slice(0, 10)}.xlsx`
  );
};


  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("on page change:", currentPage, limit, search, sort, sortBy);
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy} = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  toggleFeaturedFilter = () => {
    this.setState(prevState => ({
      featuredOnly: !prevState.featuredOnly
    }));
  }

  updateActivate = async (status, id) => {
    try {
      const authHeaderObject = authHeader(); // Assuming this returns the necessary headers
      const response = await axios.patch(`${API_URL}vendor/activate/${id}`, { activate: status }, {
        headers: {
          ...authHeaderObject,
          'Authorization': 'Bearer YOUR_AUTH_TOKEN_HERE' // Replace with dynamic token retrieval
        }
      });

      //console.log(response.data); // Log the response data for debugging purposes
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
    } catch (err) {
      console.error('Error updating activation status:', err);
      // Handle the error appropriately, maybe show a notification to the user
    }
  };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
      this.updateURLParams();
    });
  }

  columns = [
    { name: "SL", cell: (row, index) => <p className='text-center'>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },

    {
      name: 'Logo',
      cell: (row) => (
        <img
          src={
            row && row.photo
              ? row.photo
              : 'https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png'
          }
          alt={row.full_name}
          style={{ width: "50%" }}
        />
      ),
      minWidth: '200px',
    },
    {
      name: "Vendor Name", cell: (data) => {
        return (
          <a href={`/vendor/${data._id}`} target="_blank" rel="noreferrer">
          {data.first_name && data.surname_name ? `${data.first_name} ${data.surname_name}` : ''}
          </a>
        )
      }, width: '250px',
      sortField: 'full_name',
      sortable: true,
    },
    {
      name: "Business Name", cell: (data) => {
        return (
        // <p>{data.business_name ? data.business_name : ''}</p>
        <a href={`/vendor/${data._id}`} target="_blank" rel="noreferrer">
          {data.business_name ? data.business_name : ''}
        </a>
      )
      }, width: '200px',
      sortField: 'business_name',
      sortable: true,
    },
    {
      name: "Username", cell: (row) => (
        <a
          href={APP_URL + row.username}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row && row.username}
        </a>
      ), width: '200px',
      sortable: true,
      sortField: 'username'
    },
    {
      name: "Phone", cell: (row) => (
        <div className="d-flex justify-content-between align-items-center">
          <a href={'tel:' + (row && row.phone)}>{row && row.phone}</a>{' '}
          <a
            href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(row.phone)}&text=${encodeURIComponent(`Hello ${row.full_name}, 👋🏻
Thank you for joining LOFAZ. 😊

👉🏻 Here is your Website URL - Lofaz.com/${row.username}   
Share this URL with your friends & colleague so that they can place the order on your website 🚀

My name is Chirag and I’m reaching out from LOFAZ Support Team 👨🏻‍💻
Let us know if you need any help in settings up your Business 🏢

You can also call us or WhatsApp us any time on the same no +91 7272 8989 47

🌟 Join the LOFAZ Seller Community!🌟
for the latest updates, E-commerce Tips, Customer attraction , and Expert strategies .🤩 
https://chat.whatsapp.com/BB9wXbHi2xm4b8liUlkDMM`)}
`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp-square fa-2x ml-2 wap"></i>
          </a>
        </div>),
      width: '200px', sortField: 'phone',
      sortable: true,
    },
    {
      name: "Category", cell: (data) => {
        return (<p>{data.acc_category ? data.acc_category : '-'}</p>)
      }, width: '150px',
      sortable: true,
      sortField: 'acc_category'
    },
    {
      name: "Active Catalogs", cell: (data) => {
        return (
        <p>
          {data.total_active_category ?? 0}
        </p>
      )
      }, width: '150px',
      sortField: 'total_active_category',
      sortable: true,
    },
    {
      name: "Inactive Catalogs", cell: (data) => {
        return (
        <p>
          {data.total_inactive_category ?? 0}
        </p>
      )
      }, width: '150px',
      sortField: 'total_inactive_category',
      sortable: true,
    },
    {
      name: "Total Catalogs", cell: (data) => {
        return (
        <p>
          {data.total_category ?? 0}
        </p>
      )
      }, width: '150px',
      sortField: 'total_category',
      sortable: true,
    },
    {
      name: "Active Products", cell: (data) => {
        return (
        <p>
          {data.total_product_active ?? 0}
        </p>
      )
      }, width: '150px',
      sortField: 'total_product_active',
      sortable: true,
    },
    {
      name: "Inactive Products", cell: (data) => {
        return (
        <p>
          {data.total_product_inactive ?? 0}
        </p>
      )
      }, width: '150px',
      sortField: 'total_product_inactive',
      sortable: true,
    },
    {
      name: "Total Products", cell: (data) => {
        return (
          <p>
            {data.total_product ?? 0}
          </p>
        )
      }, width: '150px',
      sortField: 'total_product',
      sortable: true,
    },
    {
      name: "Orders", cell: (data) => {
        return (
        <p>
          {`${data.total_order ?? 0}`}
        </p>
      )
      }, width: '150px',
      sortable: true,
      sortField: 'total_order'
    },
    {
      name: "Wishlist", cell: (data) => {
        return (<p>{data.total_wishlist ? data.total_wishlist : '0'}</p>)
      }, width: '150px',
      sortable: true,
      sortField: 'total_wishlist'
    },
    {
      name: "Cart", cell: (data) => {
        return (<p>{data.total_cart ? data.total_cart : '0'}</p>)
      }, width: '150px',
      sortable: true,
      sortField: 'total_cart'
    },
    {
      name: "Featured",
      cell: row => {
        const handleToggle = () => {
          if (row) {
            const newActivateState = !row?.activate;
            this.updateActivate(newActivateState, row?._id);
          }
        };
        return (
          <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
            {row?.activate ? (
              <span className="btnn btn-dark p-2 container material-symbols-outlined text-center mx-auto">
                star
              </span>
            ) : (
              <span className="btnn material-symbols-outlined p-2 container text-center mx-auto px-auto" style={{ color: 'grey' }}>
                star_outline
              </span>
            )}
          </div>
        );
      },
      width: '150px',
      sortField: 'activate',
      //sortable: true,
      
    },
    // {
    //   name: "Label", cell: (data) => {
    //     //console.log(data);
    //     return (<p>{datatotal_order ? datatotal_order : ''}</p>)
    //   }, width: '150px'
    // },
    {
      name: "Created On",
      selector: (row) => {
        const date = new Date(row.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'createdAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      minWidth: '200px',

    },
    {
      name: "Last Login",
      selector: (row) => {
        const date = new Date(row.updatedAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'updatedAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      minWidth: '200px',

    },
    {
      name: 'View Profile',
      cell: row => (
        <>
        <ViewProfileButton
          viewUrl={APP_URL + row.username}
          isActive={row.activate}
          vendorDetailsUrl={`/vendor/${row._id}`}
          handleDelete={() => handleDelete(row._id, 'Vendor')}
          handleGuestLogin={async () => {
            const date = new Date();
            const url =
              VENDOR_PANEL_URL +
              'login?guest=' +
              encodeURIComponent(
                CryptoJS.AES.encrypt(
                  row && row.phone,
                  process.env.REACT_APP_GUEST_SECRET +
                  String(Number(date.getSeconds()) > 30 ? 0 : 1) +
                  date.getMinutes() +
                  date.getHours() +
                  date.getDay() +
                  date.getMonth() +
                  date.getFullYear()
                ).toString()
              );
              console.log(url,row.phone);
            window.open(url, '_blank');
          }
        }
        />
      </>
      ),
      minWidth: '300px'
    },
    {
      name: 'Admin Status',
      cell: row => {
        // const handleToggle = () => {
        //   const newActivateState = !row?.activate;
        //   this.updateActivate(newActivateState, row?._id);
        // };
        // const handleToggle = () => {
        //   setIsModalOpen(prev => !prev);
        // };
      
        // const handleClose = () => {
        //   setIsModalOpen(false);
        // };
      
        //not using handleToggle since featured bhi toggle hota dono ka same API field hai
        return (
          <div>
            <Toggle
              // defaultChecked={true}
              color="primary"
              name="activate-switch"
              // checked={this.state.open}
              onChange={this.handleToggleChange}
              inputProps={{ "aria-label": "activate switch" }}
            />
            {/* <Button onClick={this.handleOpen}>Open modal</Button> */}
            <Modal
              open={this.state.modalOpen}
              onClose={this.handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Text in a modal
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </Typography>
              </Box>
            </Modal>
          </div>
        );
      },
      minWidth: '200px'
    }
  ];


  // handleStatusChange = async (id, newStatus) => {
  //   // Make API call to update the status on the server
  //   try {
  //     await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
  //       headers: authHeader(),
  //     });
  //     const updatedData = this.state.data.map(item =>
  //       item._id === id ? { ...item, active: newStatus } : item
  //     );
  //     this.setState({ data: updatedData });
  //   } catch (error) {
  //     console.error("Error updating catalog status:", error);
  //   }
  // };

  render() {
    const { data, fetched, currentPage, search, limit, totalRows } = this.state;
    const { history } = this.props;
    // // Apply the filtering here based on featuredOnly state
    // const filteredData = this.state.featuredOnly ? data.filter(item => item.activate===this.state.featuredOnly) : data;
    // console.log("filter",filteredData);


    

    // Conditional rendering based on whether data has been fetched
    if (!fetched || data.length === 0) {
      return (
        <Container>
          {/* Replace with your actual loading component */}
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="spinner-border text-body" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Container>
      );
    }

    return (
      <Container>
        <div className="mb-3 d-flex mt-4 justify-content-start text-center">
          <div className="col-12 col-xl-2 col-sm-2 mt-2">
            <h3>Vendor Stores</h3>
          </div>
          <div className="col-12 col-xl-3 col-sm-3">
            <input
              type="text"
              className="form-control"
              aria-label="select search by"
              placeholder="Search by business name"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>

          {/* Wrap the buttons in a new div */}
          <div className="ml-auto d-flex align-items-center">
            <AddButton
              label="Import"
              handler={() => history.push("vendor/select")}
            />
            <AddButton
              label="New Vendor"
              handler={() => history.push("/vendor/new")}
            />
            <button className="btn btn-dark mr-2" onClick={this.downloadAsXls}>
              <i className="fa fa-file-download" />
            </button>
            <button
              className="btn btn-dark"
              onClick={this.toggleFeaturedFilter}
            >
              {this.state.featuredOnly ? "All Stores" : "Featured Only"}
            </button>
          </div>
        </div>
        <DataTable
          title=""
          // searchByList={[
          //   { label: "Catalog Name", value: "title" },

          //   // {
          //   //   label: "Vendor Business Name",
          //   //   value: ({ data }) => (data ? data["shop"]["business_name"] : ""),
          //   // },
          // ]}
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "VendorList Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ data }) => (data ? data["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getProductTableConfig(history)}
          data={data}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]} // Options for rows per page
          paginationTotalRows={totalRows} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
      </Container>
    );
  }
}

export default VendorList;
