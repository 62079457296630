import { connect } from "react-redux";
import DataTable from "./component/common/DataTable";
import store, { AsyncThunks } from "./redux/store";
import UserService from "./services/user.service";


// export const API_URL = "https://betabackend.lofaz.in/api/";
export const API_URL = "https://api.lofaz.com/api/";
// export const API_URL = "http://localhost:2000/api/";
export const APP_URL = "https://lofaz.com/";
export const VENDOR_PANEL_URL = "https://web.lofaz.com/";

export function getLast7Days() {
  var result = [];
  for (var i = 0; i < 7; i++) {
    var d = new Date();
    d.setDate(d.getDate() - i);
    result.push(d.toString().split(" ")[0]);
  }

  return result.reverse();
}
export function checkAuthed(history) {
  const x = localStorage.getItem("user");
  if (x === null) {
    return history.push("/");
  }
  return x;
}

export const handleDelete = async (_id, what) => {
  const doDelete = window.confirm(
    `Are you sure you want to delete this ${what}?`
  );
  if (doDelete) {
    try {
      const res = await UserService.delete[what](_id);
    if (res.status === 200) {
      store.dispatch(AsyncThunks[`fetch${what}List`]());
      alert(`${what} deleted successfully.`);
      window.location.reload();
    }
    } catch (error) {
      alert(`Something went wrong. ${what} not deleted!`);
      console.log("error del",error);
    } 
  }
};
export const cookieFetcher = (cookieName) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieParts = decodedCookie.split(';');
  for (let i = 0; i < cookieParts.length; i++) {
    let cookie = cookieParts[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

export function wrapDataTable(what) {
  return connect(
    (state) => ({
      fetched: state[`${what}Reducer`].fetched,
      dataList: state[`${what}Reducer`].dataList,
    }),
    (dispatch) => ({
      fetchDataList: () => dispatch(AsyncThunks[`fetch${what}List`]()),
    })
  )(DataTable);
}

export function formatDateToYYYYMMDD(dateString) {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = `${dateObject.getMonth() + 1}`.padStart(2, "0");
  const day = `${dateObject.getDate()}`.padStart(2, "0");
  return `${year}-${month}-${day}`;
}